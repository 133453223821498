<template>
  <div>
    <div class="uk-inline uk-width-1-1">

      <div class="uk-align-left uk-margin-remove-bottom">
        <h1 class="uk-margin-remove-bottom">{{ $t('Sidebar.Apps') }}</h1>
      </div>

      <div>
        <button class="uk-button uk-button-primary uk-align-right uk-margin-remove new-btn uk-width-auto" href="#modal-newapp" uk-toggle>
          <span uk-icon="plus-circle"></span>&nbsp;&nbsp;{{ $t('Actions.NewApp') }}</button>
      </div>

      <div class="uk-margin-small-right uk-margin-small-left uk-align-right uk-margin-remove-bottom">
        <div>
          <button :uk-tooltip="$t('Generic.Labels.Filter')" :aria-label="$t('Generic.Labels.Filter')" style="padding-left:10px; padding-right:10px; width:42px" class="uk-button uk-button-primary" type="button">
            <img src="@/assets/filter-icon.svg" :alt="$t('Generic.Labels.Filter')" uk-svg /> 
          </button>
          <div uk-dropdown="mode: hover; pos: bottom-left; animation: uk-animation-slide-top-small; duration: 500; delay-hide: 200" style="min-width: 125px">
            <ul class="uk-nav uk-dropdown-nav">
                <li class="uk-text-left uk-text-default" :class="filter === selectedFilter? 'uk-active uk-text-bold': '' " v-for="filter in devicesInApps" :key="filter" :value="filter" > <a @click="selectFilter(filter)"> {{$t('enums.DeviceType')[filter]}} </a> </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="uk-align-right uk-margin-remove-bottom ">
        <form class="uk-search uk-width-auto@m uk-search-default" @submit.prevent="">
            <span class="uk-search-icon" uk-search-icon></span>
            <a v-if="searchKey" class="uk-form-icon uk-form-icon-flip" uk-icon="icon: close" @click.prevent="clearSearch()"></a>
            <input @blur="updateSearchKeyInURL()" class="uk-search-input uk-width-medium" type="search" v-model.trim="searchKey" :placeholder="$t('Generic.Labels.Search')">
        </form>
      </div>

    </div>
    <hr />
    <div 
    v-if="filteredApps && filteredApps.length"
      class="uk-grid-small uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-child-width-1-5@xl"
      uk-scrollspy="target: > div.uk-grid-item-match; cls: uk-animation-scale-up; delay: 20; offset-top: 2000;"
      uk-grid
    >
      <div 
        v-for="(app, i) in filteredApps" 
        :key="i"
        class="uk-grid-item-match"
      >
        <AppCard 
          :appData="app"
        />
      </div>
    </div>
    <span v-if="loading && !( filteredApps && filteredApps.length)" uk-spinner />
    <span v-if="!loading && !( filteredApps && filteredApps.length)" class="uk-text-meta">
      {{$t('Pages.Application.NoApps') + (this.searchKey ? '' : $t('Pages.Application.NoAppsExt'))}}
      <a v-if="!this.searchKey" class="uk-text-primary" href="#modal-newapp" uk-toggle>{{$t('Actions.NewApp')}}</a>
    </span>
    <div id="modal-newapp" uk-modal bg-close="false" class="uk-modal-container">
      <div class="uk-modal-dialog">
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-left">{{ $t( 'Pages.Application.NewApp' ) }}</h2>
        </div>
        <div class="uk-modal-body">
          <form class="uk-form-horizontal uk-margin-large uk-text-left" @submit.prevent="saveNewApp();">
            <div class="uk-child-width-expand@s" uk-grid>
              <div>
                <span class="uk-text-large">{{ $t( 'Pages.Application.AppDetail' ) }}</span>
                <div class="uk-margin-small">
                  <label class="uk-form-label" for="app-platform">{{ $t( 'Pages.Application.SelectPlatform' ) }}</label>
                  <div class="uk-form-controls">
                    <select class="uk-select" id="app-platform" v-model="form.app.type">
                      <option
                        v-for="(device, di) in availableDevices"
                        :key="di"
                        :value="device"
                      >
                        {{ $t( `enums.DeviceType.${device}` ) }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="uk-margin-small">
                  <label class="uk-form-label" for="app-name">{{ $t( 'Pages.Application.AppName' ) }} <span class="uk-text-danger">*</span></label>
                  <div class="uk-form-controls">
                    <div class="uk-inline uk-width-1-1">
                      <input class="uk-input" id="app-name" type="text" 
                        :placeholder="$t( 'Pages.Application.Placeholder.AppName' )" v-model.trim="form.app.name"
                        :class="{ 'invalid-border': !form.valid.name }" @keyup="validateInput( 'name' )">
                      <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="!form.valid.name" uk-icon="icon: warning" ></span>
                      <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="!form.valid.name" > {{ $t( 'Generic.Errors.Min2Chars' ) }}</span>
                    </div>
                  </div>
                </div>
                <div class="uk-margin-small">
                  <label class="uk-form-label" for="app-description">{{ $t( 'Pages.Application.AppDescription' ) }}</label>
                  <div class="uk-form-controls">
                    <textarea class="uk-textarea" id="app-description"
                      :placeholder="$t( 'Pages.Application.Placeholder.AppDescription' )" v-model.trim="form.app.description"></textarea>
                  </div>
                </div>
                <div class="uk-margin-small">
                  <label class="uk-form-label" for="app-version">{{ $t( 'Pages.Application.VersionNumber' ) }}</label>
                  <div class="uk-form-controls">
                    <input class="uk-input" id="app-version" type="text" 
                      :placeholder="$t( 'Pages.Application.Placeholder.VersionNumber' )" v-model.trim="form.app.currentVersion">
                  </div>
                </div>
                <div class="uk-margin-small">
                  <label class="uk-form-label" for="app-properties">{{ $t( 'Pages.Application.AssignProperties' ) }}</label>
                  <div class="uk-form-controls uk-position-relative">
                      <Multiselect
                        :dataSet="appPropsForDropdown || []"
                        v-model="form.selectedProperties"
                        :resetForm="resetForm"
                        :placeholder="$t('Pages.Application.SelectAppProps')"
                      />
                  </div>
                </div>
              </div>
              <div>
                <span class="uk-text-large">{{ $t( 'Pages.Application.AppConfig' ) }}</span>
                <div class="uk-margin-small">
                  <label class="uk-form-label" for="app-analytics-id">{{ $t( 'Pages.Application.AnalyticsID' ) }}</label>
                  <div class="uk-form-controls">
                    <input class="uk-input" id="app-analytics-id" type="text" 
                      :placeholder="$t( 'Pages.Application.Placeholder.AnalyticsID' )" v-model.trim="form.app.amplitudeProjectId">
                  </div>
                </div>
                <div class="uk-margin-small">
                  <label class="uk-form-label" for="app-analytics-key">{{ $t( 'Pages.Application.AnalyticsKey' ) }}</label>
                  <div class="uk-form-controls">
                    <input class="uk-input" id="app-analytics-key" type="text" 
                      :placeholder="$t( 'Pages.Application.Placeholder.AnalyticsKey' )" v-model.trim="form.app.amplitudeKey">
                  </div>
                </div>
                <div class="uk-margin-small">
                  <label class="uk-form-label" for="app-bundle-id">{{ $t( 'Pages.Application.BundleID' ) }}</label>
                  <div class="uk-form-controls">
                    <div class="uk-inline uk-width-1-1">
                      <input class="uk-input" id="app-bundle-id" type="text" 
                        :placeholder="$t( 'Pages.Application.Placeholder.BundleID' )" v-model.trim="form.app.bundleId"
                        :class="{ 'invalid-border': !form.valid.bundleId }" @keyup="validateInput('bundleId')" >
                      <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="!form.valid.bundleId" uk-icon="icon: warning" ></span>
                      <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="!form.valid.bundleId" > {{ $t( 'Generic.Errors.Min2Chars' ) }}</span>
                    </div>
                  </div>
                </div>
                <div class="uk-margin-small">
                  <label class="uk-form-label" for="app-store-url">{{ $t( 'Pages.Application.StoreUrl' ) }} </label>
                  <div class="uk-form-controls">
                    <div class="uk-inline uk-width-1-1">
                      <input class="uk-input" id="app-store-url" type="text" 
                        :placeholder="$t( 'Pages.Application.Placeholder.StoreUrl' )" v-model.trim="form.app.storeUrl"
                        :class="{ 'invalid-border': !focused.storeUrl && !form.valid.storeUrl }" @keyup="validateInput('storeUrl')"
                        @focus="focused.storeUrl = true" @blur="focused.storeUrl = false">
                      <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="!focused.storeUrl && !form.valid.storeUrl" uk-icon="icon: warning" ></span>
                      <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="!focused.storeUrl && !form.valid.storeUrl" > {{ $t( 'Generic.Errors.InvalidUrl' ) }}</span>
                    </div>
                  </div>
                </div>
                <div class="uk-margin-small">
                  <label class="uk-form-label" for="app-passcode">{{ $t( 'Pages.Application.TestingPasscode' ) }}</label>
                  <div class="uk-form-controls">
                    <input class="uk-input" id="app-passcode" type="text" 
                      :placeholder="$t( 'Pages.Application.Placeholder.TestingPasscode' )" v-model.trim="form.app.debugPasscode">
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close uk-margin-right" type="button">{{ $t( 'Actions.Cancel' ) }}</button>
          <button v-if="!saving" class="uk-button uk-button-primary" type="button" @click.prevent="saveNewApp();">{{ $t( 'Actions.Save' ) }}</button>
          <button v-else class="uk-button uk-button-primary" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AppCard from '@/components/cards/AppCard.vue';
import Notification from '@/components/util/Notification';
import { DeviceType } from '@/utils/enums.js';
import Multiselect from '@/components/multiselect/Multiselect'

const validUrlPattern = /^(http(s)?:\/\/){1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/; // eslint-disable-line

export default {
  name: 'AppsView',
  components: {
    AppCard,
    Multiselect
  },
  data() {
    return {
      fetching: false,
      availableDevices: DeviceType.valuesAlphabetical,
      resetForm: 0,
      form: {},
      saving: false,
      selectedFilter:  this.$route?.query?.filter || 'ALL',
      devices: this.$t('enums.DeviceType'),
      searchKey:  this.$route?.query?.search || '',
      orgId: this.$route.params.id,
      loading: false,
      focused: {
        storeUrl: false
      }
    };
  },
  computed: {
    ...mapState({
      applications: state => state.venom.org?.orgApps?.nodes || [],
      pageInfo: state => state.venom.org?.orgApps?.pageInfo || {},
      appProperties: state => state.venom.org?.appProperties || []
    }),
    appPropsForDropdown () {
      const appProps = []
      this.appProperties?.forEach(prop => appProps.push({
        label: prop.i18nName?.localized?.value,
        value: prop.id,
        selected: false
      }))
      return appProps
    },
    selectedAppProperties () {
      return this.form.selectedProperties?.map(prop => prop.value)
    },
    getOrgId() {
      return this.$route.params.id
      // return ( this.orgId && this.orgId === this.$route.params.id ) ? this.orgId : this.$route.params.id;
    },
    devicesInApps () {
      const devices = new Set()
      devices.add('ALL')
      for (let app of this.applications) {
        devices.add(app.type)
      }
      return devices
    },
    filteredApps () {
      return this.applications.filter(app => {
        return (app.name.toLowerCase().includes(this.searchKey.toLowerCase()) && (this.selectedFilter === 'ALL' ?
        true :
        app.type === this.selectedFilter ))

      })
    }
  },
  beforeMount() {
    this.form = this.defaultFormData();
  },
  mounted() {
    document.documentElement.scrollTop = 0
    this.fetchApps()
    window.UIkit.util.on(document, 'beforeshow', '#modal-newapp', () => {
      const modal = document.getElementById("modal-newapp");
      if (!modal?.classList?.contains('uk-open')) {
        this.form = this.defaultFormData();
        this.getAppProperties();
      }
    });
  },
  methods: {
    async fetchApps () {
      this.loading = true
      await this.$store.dispatch('fetchAllOrgApps', { orgId: this.orgId })
      this.loading = false
    },
    async getAppProperties() {
      await this.$store.dispatch( 'getAppProperties', {
        orgId: this.getOrgId
      } )
    },
    propertyName( property ) {
      return property?.i18nName?.localized?.value || 'N/A';
    },
    validateInput( field ) {
      let isValid = false;
      if ( field === 'name' && this.form.app.name.length >= 2 ) {
        isValid = true;
      } 
      else if ( field === 'bundleId' && (!this.form.app.bundleId || this.form.app.bundleId.length >= 2 || !this.form.app.bundleId.trim()) ) {
        isValid = true;
      } else if ( field === 'storeUrl' && (( this.form.app.storeUrl && validUrlPattern.test( this.form.app.storeUrl)) || !this.form.app.storeUrl)) {
        isValid = true;
      }
      this.form.valid[ field ] = isValid;
      return isValid;
    },
    async saveNewApp() {
      const isValidName = this.validateInput( 'name' );
      const isValidBundleId = this.validateInput( 'bundleId' );
      const isValidStoreUrl = this.validateInput( 'storeUrl' );
      if ( isValidName && isValidBundleId && isValidStoreUrl) {
        this.saving = true;
        const params = {
          appData: this.form.app
        }
        if (this.selectedAppProperties?.length) {
          params.appData.properties = this.selectedAppProperties
        }
        if (!params.appData?.storeUrl) delete params.appData.storeUrl
        if (!params.appData?.bundleId) delete params.appData.bundleId
        const appId = await this.$store.dispatch( 'saveOrgNewApp', {
          appData: this.form.app
        } );
        this.saving = false;
        if( appId ) {
          window.UIkit.modal( '#modal-newapp' ).$destroy(true);
          Notification.showNotification( this.$t( 'Generic.Messages.Successful' ), this.$t( 'Pages.Application.Notification.NewAppCreated' ) );
          this.$router.push({ name: 'AppEdit', params: { id: this.getOrgId, appId: appId } });
        }
      }
    },
    defaultFormData() {
      this.resetForm++
      return {
        app: {
          amplitudeKey: '',
          amplitudeProjectId: '',
          bundleId: '',
          currentVersion: '',
          debugPasscode: '',
          description: '',
          name: '',
          organization: this.getOrgId,
          properties: [],
          storeUrl: '',
          type: 'ANDROID'
        },
        valid: {
          name: true,
          bundleId: true,
          storeUrl: true
        }
      };
    },
    updateSearchKeyInURL () {
      const url = new URL(window.location);
      url.searchParams.set('search', this.searchKey);
      window.history.pushState({}, '', url);
    },
    selectFilter (filter) {
      this.selectedFilter = filter
    },
    clearSearch () {
      this.searchKey = '';
      const url = new URL(window.location);
      url.searchParams.delete('search');
      window.history.pushState({}, '', url);
    }
  },
  watch: {
    selectedFilter: {
      handler () {
        const url = new URL(window.location);
        url.searchParams.set('filter', this.selectedFilter);
        window.history.pushState({}, '', url);
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.tooltip {
  color: #e69393;
}

.new-btn {
  padding: 0 20px;
}
</style>
<template>
  <div class="">
    <div class="uk-inline uk-width-1-1">
      <input type="text" class="uk-input" :class="{ 'invalid-border' : errors }" @focus="showSuggestion = true" @blur="showSuggestion = false" v-model="searchKey" :tabindex="0" :placeholder="errors ? '' : placeholder ">
      <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="errors" uk-icon="icon: warning"></span>
      <span class="uk-form-icon uk-margin-small-left uk-float-right inline-error-bg uk-text-truncate uk-width-3-4" v-if="errors" >{{errors}}</span>
    </div>
    <div uk-dropdown="mode: click" id='autocomplete-suggestions-drop' class="uk-margin-top uk-height-max-medium uk-overflow-auto uk-padding-remove uk-width-1-1">
      <ul v-if="dataSet && options.length" class="uk-list uk-list-striped">
        <li class="uk-flex">
          <div class="uk-width-auto uk-margin-right">
            <a href="#"  class="uk-width-1-6 uk-text-decoration-none" :tabindex="1" @keyup.enter="checkApp(data, i)">
              <input type="checkbox" class="uk-checkbox" :checked="checkAll(true)" :tabindex="1" @click="checkAll()">
            </a>
          </div>
          <div class="">
            All
          </div>
        </li>
        <li v-for="(data, i) in options" :key="data.value" class="uk-flex">
          <div class="uk-width-auto uk-margin-right">
            <a href="#"  class="uk-width-1-6 uk-text-decoration-none" :tabindex="i+2" @keyup.enter="checkApp(data, i)">
              <input type="checkbox" class="uk-checkbox" :checked="checkApp(data, i, true)" :tabindex="-1" @click="checkApp(data, i)">
            </a>
          </div>
          <div class="uk-text-truncate">
            {{data.label}}
          </div>
        </li>
      </ul>
    </div>
    
    <div class="uk-height-max-small uk-overflow-auto" v-if="showSelectedOptions">
      <div v-for="app in selectedApps" :key="app.value" class="tag-item uk-margin-small-top uk-margin-small-left" style="max-width:95%">
        <div class="label uk-margin-small-left uk-margin-small-right  uk-text-truncate uk-width-auto" :uk-tooltip="`title: ${app.label}; pos: bottom`" >
            <span :key="app.value"> {{ app.label }} </span>
        </div>
        <span class="close uk-margin-auto-vertical uk-margin-small-right" style="cursor: pointer;" uk-icon="icon: close;ratio: 0.8" @click="app.selected = !app.selected"></span>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'Multiselect',
  props: {
    dataSet: {
      type: Array,
      default: null
    },
    showSelectedOptions: {
      type: Boolean,
      default: true
    },
    value: {
      type: [ Object, String, Array ],
      default: null
    },
    errors: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    resetForm: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      showSuggestion: false,
      searchKey: '',
      apps: [],
    }
  },
  computed: {
    options () {
      if (this.apps?.length) {
        return this.apps.filter(item => item.label.toLowerCase().includes(this.searchKey?.toLowerCase()))
      } else {
        return []
      }
    },
    selectedApps () {
      return this.apps.filter(app => app.selected)
    }
  },
  methods: {
    checkApp (app, i, preview) {
      //  i - was passed so as to update the position using Vue.set()
      if (preview) return app.selected
      app.selected = !app.selected
      return app.selected
    },
    checkAll (preview) {
      if (preview) {
        let checked = true
        for (const app of this.apps) {
          if (!app.selected) {
            checked = false
            break
          }
        }
        return checked
      } else {
        let selectionStatus = true
        if (this.checkAll(true)) {
          selectionStatus = false
        }
        this.apps.forEach(app => {
          app.selected = selectionStatus
        })
      }
    },
    deleteApp (app) {
      app.selected = !app.selected
    },
  },
  created () {
    for (let i = 0; i < this.dataSet.length ; i++) {
      this.$set(this.apps, i, this.dataSet[i])
    }
    // window.UIkit.util.on(document, 'beforeshow', '#autocomplete-suggestions-drop', (e) => {
    //   if (!this.showSuggestion) {
    //     e.preventDefault()
    //   }
    // })
  },
  watch: {
    selectedApps () {
      this.$emit( 'input', this.selectedApps || [] ); // Updating the parent v-model input on every change
    },
    resetForm () {
      this.apps.map(app => {
        app.selected = false
        return app
      })
      this.searchKey = ''
    },
    dataSet () {
      for (let i = 0; i < this.dataSet.length ; i++) {
        this.$set(this.apps, i, this.dataSet[i])
      }
    }
    // showSuggestion () {
    //   const dropElement = document.getElementById('autocomplete-suggestions-drop')
    //   if (this.showSuggestion) {
    //     window.UIkit.drop(dropElement).show()
    //   } else {
    //     window.UIkit.drop(dropElement).hide()
    //   }
    // }
  }
}
</script>

<template>
  <div class="uk-card uk-card-default uk-card-small uk-text-left uk-card-hover uk-border-rounded">
    <router-link :to="appProfileUrl" style="text-decoration: none;">      
      <div class="uk-card-body">
        <div class="uk-grid-small">
          <div class="uk-flex uk-width-1-1 uk-flex-middle">
            <ImageOrAbbrevation
              :src="getImgUrl"
              :alt="appData.name"
              :imageClass="imageClass"
            />
            <div class="uk-margin-small-left uk-width-expand vertical-center"> 
              <h2 class="uk-card-title uk-margin-remove-top uk-margin-remove-bottom uk-text-left uk-margin-small-right uk-text-truncate"
                :uk-tooltip="tooltipTitle">{{ appData.name }}</h2>
            </div>
          </div>
        </div>
      </div>
    </router-link> 
    <div class="uk-card-footer">
      <div class="uk-text-meta uk-float-right">
        <span v-if="deleting" uk-spinner="ratio: 0.8" />
        <div v-else uk-icon="trash" class="uk-link" @click.stop="deleteApp()"></div>
      </div>
      <div class="uk-flex-inline">
        <label class="uk-text-bold">{{ $t( `enums.DeviceType.${this.appData.type}` ) }}</label>
        <div class="uk-margin-small-left">
          <span v-if="appData.store.published" class="uk-text-success uk-text-small">{{ $t( 'Pages.Application.Status.Published' ) }}</span>
          <span v-else class="uk-text-meta uk-text-small">{{ $t( 'Pages.Application.Status.Staging' ) }}</span>
          <span class="uk-text-meta uk-text-small"> {{ appVersion }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageOrAbbrevation from '@/components/images/ImageOrAbbrevation.vue';
import Notification from '@/components/util/Notification';

export default {
  name: 'AppCard',
  components: {
    ImageOrAbbrevation
  },
  data() {
    return {
      imageClass: 'uk-border-circle',
      deleting: false,
      orgId: this.$route.params.id
    }
  },
  props: {
    appData: {
      type: Object
    }
  },
  computed: {
    appProfileUrl: function() {
      return { name: 'AppEdit', params: { id: this.orgId, appId: this.appData.id } };
    },
    appVersion() {
      return this.appData.store.currentVersion ? 'v' + this.appData.store.currentVersion : '';
    },
    getImgUrl() {
      const url = this.appData.icon?.original?.url || this.appData.icon?.size64?.url;
      if ( url === undefined )  return '';
      return url;
    },
    tooltipTitle() {
      return `title: ${this.appData.name}; pos: top-left;`;
    }
  },
  methods: {
    async deleteApp() {
      const appName = this.appData.name;
      const UIkit = window.UIkit;
      UIkit.modal.confirm(this.$t( 'DeleteModal.ConfirmDeleteApplication', { name: appName }), { labels: {
                cancel: this.$t('Actions.Cancel'), ok: this.$t('Actions.Delete')
            }})
      .then( async () => {
        this.deleting = true;
        const response = await this.$store.dispatch( 'deleteApplication', { orgId: this.orgId, appId: this.appData.id } );
        if ( response ) {
          Notification.showNotification(this.$t( 'DeleteModal.Removed'), this.$t( 'DeleteModal.HasBeenRemoved', { name: appName }));
        } else {
          Notification.showNotification(this.$t( 'DeleteModal.NotRemoved'), this.$t( 'DeleteModal.CouldNotRemove', { name: appName }), 'error');
        }
        this.deleting = false;
      }, () => {
      });
    }
  }
}
</script>